// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--y+EZc";
export var breakme = "styles-module--breakme--WoaP3";
export var content = "styles-module--content--ev5mB";
export var header = "styles-module--header--IUms2";
export var holder = "styles-module--holder--mhuiT";
export var howHolder = "styles-module--howHolder---K1qO";
export var image = "styles-module--image--u+p02";
export var kaynakca = "styles-module--kaynakca--UbBPn";
export var kidsHolder = "styles-module--kidsHolder--AMRFJ";
export var listHolder = "styles-module--listHolder--4WYNT";
export var productHolder = "styles-module--productHolder--CGO8d";
export var rightCoughHolder = "styles-module--rightCoughHolder--xhVi-";
export var typesHolder = "styles-module--typesHolder--aeYiv";
export var whatHolder = "styles-module--whatHolder--m5AJ0";
export var whyHolder = "styles-module--whyHolder--ozpzs";